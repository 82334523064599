import invoiceModule from "./invoiceModule"

invoiceModule.controller("invoiceController", [
    "$scope",
    "entityEditionService",
    "autocompleteFactory",
    "sourceEntity",
    "entity",
    ($scope, entityEditionService, autocompleteFactory, sourceEntity, entity)=> {

    const updateService = entityEditionService.crudHelper("invoice", sourceEntity)
    $scope.entity = entity
    $scope.getPossibleOrders = autocompleteFactory.lazyLoader("order")

    $scope.save = updateService.save.bind(updateService, entity)
}])