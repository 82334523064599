import availabilityModule from "./availabilityModule"
import server from "../server"

availabilityModule.controller("availabilityController", [
    "$scope",
    "$q",
    "availabilityService",
    "availabilityModalService",
    "spreadsheetModal",
    ($scope, $q, availabilityService, availabilityModalService) => {

    $scope.filterService = server.getRelativeUrl("vehicle-availability")

    $scope.edit = (vehicle, origin) => {
        availabilityModalService.show(vehicle, origin).finally(() => {
            paginationApi.promise.then((api) => api.seekPage(1))
        })
    }

    const paginationApi = $q.defer()
    let filterPatch = []
    $scope.patchValue = undefined

    $scope.tableOptions = {
        records: [],
        pagination: {
            onPaginate: function (firstResult, maxResults) {
                const filterOptions = {
                    patches: filterPatch,
                    firstResult: firstResult,
                    maxResults: maxResults
                }
                return availabilityService.filterVehicles(filterOptions)
            }
        },
        onRegisterPagination: function (api) {
            paginationApi.resolve(api)
        }
    }

    $scope.search = (patch) => {
        filterPatch = patch
        $scope.patchValue = $scope.getfilteredOrigin(patch)
        return paginationApi.promise.then((api) => api.seekPage(1))
    }

    $scope.getfilteredOrigin = (patch) => {
        for(let i = 0; i < patch.length; i++) {
            const property = patch[i]
            if(property.path.includes("value")) {
                return property.value
            }
        }
        throw "É necessário filtrar por uma origem."
    }
}])