import analysisMapModule from "../analysisMapModule"

analysisMapModule.controller("tripActionsController", [
    "$scope",
    "nodeCommandResolveOrderBreak",
    "nodeCommandFinalizeTrip",
    "nodeCommandServeTrip",
    "nodeCommandCancelTrips",
    "nodeCommandPublishTrip",
    "nodeCommandReprogramTrip",
    ($scope,
     nodeCommandResolveOrderBreak,
     nodeCommandFinalizeTrip,
     nodeCommandServeTrip,
     nodeCommandCancelTrips,
     nodeCommandPublishTrip,
     nodeCommandReprogramTrip
    ) => {
        $scope.hasSelectedTrips = ()=> {
            return !!$scope.getSelectedTrips().length
        }

        $scope.hasSelectedOrdersInTripBasket = ()=> {
            return !!$scope.getSelectedOrdersFromTripBasket().length
        }

        $scope.resolveOrderBreak = () => {
            nodeCommandResolveOrderBreak($scope.getSelectedTrips()).then($scope.refilterBaskets)
        }

        $scope.serveTrips = () => {
            nodeCommandServeTrip($scope.getSelectedTrips()).then($scope.refilterBaskets)
        }

        $scope.cancelTrips = () => {
            nodeCommandCancelTrips($scope.getSelectedTrips(), {refilter: $scope.refilterBaskets})
        }

        $scope.publishTrips = () => {
            nodeCommandPublishTrip($scope.getSelectedTrips()).then($scope.refilterBaskets)
        }

        $scope.reprogramTrips = () => {
            nodeCommandReprogramTrip($scope.getSelectedTrips()).then($scope.refilterBaskets)
        }

        $scope.finalizeTrips = () => {
            nodeCommandFinalizeTrip($scope.getSelectedTrips()).finally($scope.refilterBaskets)
        }
    }])