import gatewayServer from "./gatewayServer"

const contextPath = gatewayServer.gatewayBaseUrl + "/r2-server"
const servletPath = "/r2"
const services = {
    "edition.carrier-zone-speed": "/carrier-zone/speed",
    "edition.carrier-zone-operation": "/carrier-zone/operation",
    "edition.carrier-zone-max-value": "/carrier-zone/max-value",
    "edition.carrier-zone-blocked-vehicles": "/carrier-zone/blocked-vehicles",
    "edition.carrier-zone": "/carrier-zone",
    "edition.product-category": "/product-category",
    "edition.product": "/product",
    "edition.locality": "/locality",
    "edition.trip": "/trip",
    "trip.stops": "/trip/stops",
    "search.trip": "/trip",
    "edition.vehicle": "/vehicle",
    "edition.order": "/order",
    "edition.order-type": "/order-type",
    "edition.classification": "/classification",
    "edition.generic-attribute": "/generic-attribute",
    "edition.invoice": "/invoice",
    "edition.truck": "/truck",

    "search.carrier-zone-speed": "/carrier-zone/speed",
    "search.carrier-zone-operation": "/carrier-zone/operation",
    "search.carrier-zone-max-value": "/carrier-zone/max-value",
    "search.carrier-zone-blocked-vehicles": "/carrier-zone/blocked-vehicles",
    "search.carrier-zone": "/carrier-zone",
    "search.jobs": "/job",
    "search.product-category": "/product-category",
    "search.product": "/product",
    "search.invoice": "/invoice",
    "search.locality": "/locality",
    "search.payload-record": "/payload-record",
    "search.session-records": "/session-records",
    "search.unblockTripMessages": "/unblock/trip",
    "search.vehicle": "/vehicle",
    "search.truck": "/truck",
    "search.order": "/order",
    "search.order-type": "/order-type",
    "search.classification": "/classification",
    "search.generic-attribute": "/generic-attribute",

    "city": "/city",
    "state": "/state",
    "country": "/country",
    "geocoding": "/geocoding",
    "google-maps": "/google-maps",
    "locality": "/locality",
    "user": "/user",
    "r2-all-users": "/user/find",
    "plate": "/plate",
    "vehicle": "/vehicle",
    "order": "/order",
    "orderType": "/order-type",
    "productCategory": "/product-category",
    "product": "/product",
    "invoice": "/invoice",
    "restrictions": "/restrictions",
    "allowedOrigins": "/allowed-origins",
    "userByOrigins": "/default-user-by-origin",
    "googleMaps": "/google-maps",
    "regionGroup": "/regionGroup",
    "classification": "/classification",
    "classification-type": "/classification-type",
    "generic-attribute": "/generic-attribute",
    "load-unload-duration": "/load-unload-duration",
    "integration-config": "/integration-config",
    "integration-connectivity": "/integration-connectivity",
    "transitions-config": "/transitions-config",

    "routeUrlForNodeIdentifier": "/route/nodeIdentifier",
    "routeUrlForTripIds": "/route/trips",

    "calculateTollUrlForNodeIdentifier": "/toll/nodeIdentifier",

    "alter-stops": "/alterStops/doAlterStops",
    "alter-stop-trips": "/alterStops/doAlterStopsForTrips",
    "move-stops": "/alterStops/moveStop",
    "move-trip-to-user": "/trip/toUser/",
    "move-deliveryunit-to-user": "/deliveryUnit/toUser/",
    "users": "users",

    "job-violations": "jobs/violations",
    "cancel-job": "jobs/cancel-job",
    "can-download-job": "jobs/can-download-job",
    "download-job": "jobs/download-job",

    "can-edit-trip": "/trip/can-edit",
    "edit-trip": "/trip/edit",
    "confirm-edition": "/trip/confirm-edition",

    "find-breaks": "/findBreaks",
    "resolve-order-break": "/resolveOrderBreak",
    "routingConfigSelector": "/routing-config-selector",

    "trip-publish": "/trip/publish",
    "trip-reprogram": "/trip/reprogram",
    "trip-serve": "/trip/serve",
    "status.trip": "trip/status",

    "session-record": "session-record",
    "unblockTripMessages": "/unblock/trip",
    "unblockTripMessages-forceProcess": "/unblock/trip/forceProcessMessage",
    "unblockTripMessages-resendMessage": "/unblock/trip/resendMessage",

    "polygon-carrier-zone-groupers": "/carrier-zone-grouper/polygonCarrierZoneGroupers",

    "cancelTrips": "/programming/trip/cancel-trips",
    "canCancel": "/programming/trip/can-cancel",
    "finalize-trip": "/trip/finalize",

    "preValidation-manualOperation": "/pre-validation/manual-operation",
    "preValidation-optimization": "/pre-validation/optimization",
    "organize": "/organize",
    "organize-execute": "/organize/execute",

    "manualOperationSave": "/manual-operation/save",
    "geocodeLocalities": "/locality/geocoding",
    "system-version": "/system/version",

    "doOptimize": "/programming/optimization/do-optimize",

    "orderBasketUrl": "/basket/filter",
    "orderBasketSelectionUrl": "selection/basket/filter",

    "analysisMapMarkers": "/analysis-map/markers",
    "analysisMapOptimize": "/analysis-map/do-optimize",
    "analysisMapOptimizationPreValidation": "/analysis-map/optimization-pre-validation",
    "analysisMapOrdersDetails": "/analysis-map/orders-details",

    "current-user": "/authentication/current-user",

    "authorization": "/authorization",

    "query-report": "/transactional/report/query",

    "message-config-params":"/message-config-params",

    "vehicle-availability": "/vehicle-availability",

    // Parâmetros genéricos de localidade
    "search.incompatibleVehicle":"locality-incompatible-vehicles",
    "search.allowedPlate":"locality-allowed-plate",
    "search.serviceTime":"locality-service-time",

    "getFittingInformationsByTripAndOrderId":"/stopfit/getFittingInformationsByTripAndOrderId",
    "stopFitOrderPublish":"/stopfit/stopFitOrderPublish",

    "fitOrderInTrip":"/programming/stop-fill/execute?tripId=",
    "applyManualOperation":"/programming/stop-fill/save",

    "operation-overview": "/operation-overview",

    "truck-allocation":"/transactional/programming/truckallocation",
    "trucks": "/plate",

    "invoicesByNodeIdentifiers": "invoice/findByNodeIdentifiers",

    "startMonitorableConfigurationByNodeIdentifiers": "start-monitorable-configuration/findByNodeIdentifiers",
    "saveStartMonitorableConfiguration": "start-monitorable-configuration/save",
    "deleteStartMonitorableConfiguration": "start-monitorable-configuration/delete",

    "lgpd.entity.all": "/lgpd/entity/find-all",
    "lgpd.entity.anonymize": "/lgpd/entity/anonymize",
    "lgpd.entity.by-owner": "/lgpd/entity/entities-by-owner",
    "lgpd.entity.copy": "/lgpd/entity/copy",
    "lgpd.entity.edition": "/lgpd/entity/",
    "lgpd.entity.filter": "/lgpd/entity",
    "lgpd.entity.versions": "/lgpd/entity/versions",
    "lgpd.entity.custom-filter":"/lgpd/entity/custom-filter",

    "lgpd.logs.allNames":"/lgpd/logs/all-names",
    "lgpd.logs.entitiesByName":"/lgpd/logs/entities-by-name",
    "lgpd.logs.find":"/lgpd/logs/find",
    "lgpd.logs.types":"/lgpd/logs/types",
    "lgpd.logs.exportDefinitions":"/lgpd/logs/export-definitions",

    "lgpd.obfuscate.allNames":"/lgpd/obfuscation/all-names",
    "lgpd.obfuscate.entitiesByName":"/lgpd/obfuscation/entities-by-name",
    "lgpd.obfuscate.find":"/lgpd/obfuscation/find",
    "lgpd.obfuscate.save":"/lgpd/obfuscation/save",

    "lgpd.attributable.all":"/lgpd/attributable/find-all",
    "lgpd.attributable.anonymize":"/lgpd/attributable/anonymize",
    "lgpd.attributable.edition":"/lgpd/attributable",
    "lgpd.attributable.filter":"/lgpd/attributable",

    "lgpd.classifiable.all":"/lgpd/classifiable/find-all",
    "lgpd.classifiable.anonymize":"/lgpd/classifiable/anonymize",
    "lgpd.classifiable.filter":"/lgpd/classifiable",
    "lgpd.classifiable.edition":"/lgpd/classifiable",

    "lgpd.genericParam.all":"/lgpd/generic-param/find-all",
    "lgpd.genericParam.anonymize":"/lgpd/generic-param/anonymize",
    "lgpd.genericParam.edition":"/lgpd/generic-param",
    "lgpd.genericParam.filter":"/lgpd/generic-param",

    "lgpd.sql.all":"/lgpd/script-sql/find-all",
    "lgpd.sql.copy":"/lgpd/script-sql/copy",
    "lgpd.sql.edition":"/lgpd/script-sql",
    "lgpd.sql.filter":"/lgpd/script-sql",
    "lgpd.sql.versions":"/lgpd/script-sql/versions",
    "lgpd.sql.r2-default":"/transactional/report/query/id",

    "config-params": "/config-params",
    "config-params.cache": "/config-params/cache",
    "config-params.check-for-script": "/config-params/check-for-script",
    "config-params.default-proxy": "/config-params/default-proxy",
    "config-params.default-remote-server": "/config-params/default-remote-server",
    "config-params.remote-config-params": "/config-params/remote-config-params",
    "config-params.test-routing-connection": "/config-params/test-routing-connection",
    "config-params.update": "/config-params/update",
    "config-params.update-ignoring-warnings": "/config-params/update/ignoring-warnings",
    "config-params.validate-remote-services": "/config-params/validate-remote-services",

    "orchestrator.isEnabled": "/orchestrator/enabled",
    "orchestrator.remote-type-configuration": "/orchestrator/type-configurations",
    "orchestrator.create-remote-type-configuration": "/orchestrator/create-type-configuration",
    "orchestrator.get-mapped-machine-types": "/orchestrator/get-mapped-machine-types",
    "orchestrator.update-type-configuration": "/orchestrator/update-type-configuration",
    "orchestrator.test-routing-parallelism": "/orchestrator/test-routing-parallelism",

    "services.notification.clear":"/notification",
    "services.notification.count":"/notification/count",
    "services.notification.notifications":"/notification",
    "services.notification.markConsumed":"/notification/markConsumed",
    "spreadsheet-integration": "/spreadsheet-integration",
    "new-spreadsheet-vehicle": "/vehicle/import",
    "new-spreadsheet-cancel-vehicle": "/vehicle/cancel-import",
    "new-spreadsheet-product": "/product/import",
    "new-spreadsheet-cancel-product": "/product/cancel-import",
    "new-spreadsheet-product-category": "/product-category/import",
    "new-spreadsheet-cancel-product-category": "/product-category/cancel-import",
    "new-spreadsheet-forbidden-vehicles": "/forbidden-vehicles/import",
    "new-spreadsheet-cancel-forbidden-vehicles": "/forbidden-vehicles/cancel-import",
    "new-spreadsheet-permitted-plates": "/permitted-plates/import",
    "new-spreadsheet-cancel-permitted-plates": "/permitted-plates/cancel-import",
    "spreadsheet-integration-error":"spreadsheet-integration-error",
    "new-spreadsheet-locality": "/locality/import",
    "new-spreadsheet-cancel-locality": "/locality/cancel-import",
    "new-spreadsheet-service-time":"/service-time/import",
    "new-spreadsheet-cancel-service-time":"/service-time/cancel-import",
    "new-spreadsheet-order": "/order/import",
    "new-spreadsheet-cancel-order": "/order/cancel-import",
    "new-spreadsheet-delete-order": "/order/delete",
    "new-spreadsheet-delete-locality": "/locality/delete",
    "new-spreadsheet-vehicle-availability": "/vehicle-availability/import",

    "purge-programming-database": "/database/purge",
    "purge-execution-database": "/database/purge-monitoring",

    "toggle-state-vehicle-availability": "/vehicle-availability/toggle-state",
    "find-state-vehicle-availability": "/vehicle-availability/find-state",

    "microstrategy-login": "/microstrategy/login",
    "microstrategy-configuration": "/microstrategy/configuration",
    "microstrategy-is-allowed": "/microstrategy/is-allowed"

}

class ServerUrls<T extends Record<string, string>> {
    constructor(private contextPath: string, private servletPath: string, private services: T) {}
    getBaseUrl() {
        return this.contextPath + this.servletPath
    }
    getServiceUrl(service: keyof T) {
        const relativeUrl = this.getRelativeUrl(service)
        if (!relativeUrl) {
            return relativeUrl
        }
        return this.concatPaths(this.getBaseUrl(), relativeUrl)
    }
    getRelativeUrl(service: keyof T) {
        if (!this.services[service]) {
            return ""
        }
        return this.services[service]
    }
    concatPaths(...paths: string[]) {
        let result = ""
        paths.forEach((path) => result += path + "/")
        return result.replace(/\/\//g, "/")
    }
}

export const server = new ServerUrls(contextPath, servletPath, services)
export default server

export const integration = new ServerUrls(
    gatewayServer.gatewayBaseUrl + "/integration",
    "",
    {
        "cache": "cache",
        "validator": "validator",
        "validator.dataSources": "validator/data-sources",
        "validator.interfaces": "validator/interfaces",
    })