import spreadsheetModule from "./spreadsheetModule"
import * as xlsx from "xlsx-js-style"
import examples from "./examples.json"
import definitions from "./definitions.json"
import headerLetters from "./headerLetters.json"
import headers from "./headers.json"
import urlDefinitions from "./urlDefinitions.json"
import server from "../server"

spreadsheetModule.service("spreadsheetService", [
    "$translate",
    "$q",
    "loadingService",
    "$http",
    "remoteExceptionHandler",
    "newSpreadsheetConverter",
    ($translate, $q, loadingService, $http, remoteExceptionHandler, newSpreadsheetConverter) => {
        const baseURL = server.getServiceUrl("spreadsheet-integration")

        function importRegisterErrors(id, entityName) {
            return loadingService($http.get(server.getServiceUrl("spreadsheet-integration-error") + "find-by-register-id/" + id))
                .catch(remoteExceptionHandler())
                .then(function (response) {
                    const wb = xlsx.utils.book_new()
                    const rows = []

                    response.data.forEach(function (data) {
                        let {payload, payload: {classifications, attributes}} = data
                        let fullPayload = {...payload, ...classifications, ...attributes}
                        //Só é usado para Order(Pedido)
                        newSpreadsheetConverter.convertEntityToPayload(fullPayload, entityName)
                        const mappedErrors = {}
                        data.errors.forEach(function (error) {
                            mappedErrors[error.columnName] = error.error
                        })
                        const row = headers[entityName].map(function (headerName) {
                            return getCell(nullToEmpty(fullPayload[headerName]), headerName, mappedErrors)
                        })
                        rows.push(row)
                    })
                    const ws = adaptCellsWidth(xlsx.utils.json_to_sheet([]), definitions[entityName])
                    xlsx.utils.sheet_add_aoa(ws, [headers[entityName]])
                    xlsx.utils.sheet_add_json(ws, rows, {origin: "A2", skipHeader: true})
                    xlsx.utils.book_append_sheet(wb, generateCommentByDefinition(ws, definitions[entityName]), entityName)
                    const defer = $q.defer()
                    setTimeout(function () {
                        // Executa fora do ciclo de digest
                        xlsx.writeFile(wb, $translate.instant("new.spreadsheet." + entityName + ".file.name.error"))
                        defer.resolve()
                    })
                    return defer.promise
                })
        }

        function getCell(value, columnName, errors) {
            let mappeableError = errors["internalError"]
            if (!mappeableError) {
                mappeableError = errors[columnName]
            }
            if (!mappeableError) {
                return {
                    v: value,
                    t: "s",
                    s: {alignment: {wrapText: true}}
                }
            }
            const c = []
            c.hidden = true
            c.push({a: "SheetJS", t: $translate.instant(mappeableError)})
            return {
                v: value,
                t: "s",
                c: c,
                s: {fill: {fgColor: {rgb: "ee4e4e"}}, alignment: {wrapText: true}}
            }
        }

        function nullToEmpty(value) {
            return !value ? "" : value
        }

        function newSpreadsheetImport(selectedEntity, file) {
            return loadingService($q((resolve, reject) => {
                const fileReader = new FileReader()
                fileReader.onload = () => {
                    const workbook = xlsx.read(fileReader.result, {
                        type: "binary", cellDates: true, sheetStubs: true
                    })
                    const result = {}
                    const sheetNames = workbook.SheetNames
                    if (!sheetNames.includes(selectedEntity)) {
                        reject({
                            bundle: "import.exception.invalid.sheetNames",
                            param: [sheetNames[0], selectedEntity],
                            message: "Invalid sheet length or name"
                        })
                        return
                    }
                    result[selectedEntity] = xlsx.utils.sheet_to_json(workbook.Sheets[selectedEntity]).map(function (payload) {
                        return newSpreadsheetConverter.convertPayloadToEntity(selectedEntity, payload)
                    })
                    if (result[selectedEntity] === undefined || result[selectedEntity].length === 0) {
                        reject({bundle: "import.exception.emptySheet", message: "Sheet with no rows"})
                        return
                    }
                    $http.post(server.getServiceUrl("new-spreadsheet-" + urlDefinitions[selectedEntity]) + "?entityName=" + selectedEntity, result[selectedEntity])
                        .then((response) => {
                                resolve(result)
                                response.data
                        })
                        .catch(function (e) {
                                return reject(e.data)
                            }
                        )
                }
                fileReader.readAsBinaryString(file)
            }))
        }

        function generateModel(selectedEntity) {
            generateEntityModel(examples[selectedEntity], definitions[selectedEntity], selectedEntity, $translate.instant("new.spreadsheet." + selectedEntity + ".file.name"))
        }

        const generateEntityModel = (example, definitions, sheetName, fileName) => {
            const workbook = xlsx.utils.book_new()
            let ws = adaptCellsWidth(xlsx.utils.json_to_sheet(example), example)
            xlsx.utils.book_append_sheet(workbook, generateCommentByDefinition(ws, definitions), sheetName)
            const defer = $q.defer()
            setTimeout(function () {
                // Executa fora do ciclo de digest
                xlsx.writeFile(workbook, fileName)
                defer.resolve()
            })
            return defer.promise
        }

        const generateCommentByDefinition = (ws, definitions) => {
            for (let i = 0; i < definitions.length; i++) {
                ws[`${headerLetters[i]}1`].c = []
                ws[`${headerLetters[i]}1`].c.hidden = true
                ws[`${headerLetters[i]}1`].c.push({a: "SheetJS", t: $translate.instant(definitions[i])})
            }
            return ws
        }

        const adaptCellsWidth = (ws, examples) => {
            let keys = Object.keys(examples[0]).map((k) => k.toString().length)
            let wscols = []
            for (let i = 0; i < keys.length; i++) {
                wscols.push({wch: keys[i] + 20})
            }
            ws["!cols"] = wscols
            return ws
        }
        const filterRegisters = ({patches, firstResult, maxResults}) => {
            const pageOptions = {
                firstResult: firstResult,
                maxResults: maxResults
            }
            return loadingService($http.post(baseURL, patches, {params: pageOptions}))
                .catch(remoteExceptionHandler())
                .then((response) => response.data)
        }

        function newSpreadsheetCancelImport(entityName, registerId) {
            return $http.post(server.getServiceUrl("new-spreadsheet-cancel-" + urlDefinitions[entityName]) + "?registerId=" + registerId)
        }

        return {
            newSpreadsheetImport: newSpreadsheetImport,
            generateModel: generateModel,
            filterRegisters: filterRegisters,
            importRegisterErrors: importRegisterErrors,
            newSpreadsheetCancelImport: newSpreadsheetCancelImport
        }
    }])
