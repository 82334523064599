import * as _ from "lodash"

const gatewayBaseUrl = "/cockpit-gateway"

const services = {
    "authentication": "/authentication/login",
    "token": "/authentication/token/validate",
    "web-services": "/web-services-server",
}

export default {
    gatewayBaseUrl,
    getServiceUrl(service : keyof typeof services){
        const serviceUrl = services[service]
        if (isNotEmpty(serviceUrl)) {
            return gatewayBaseUrl + serviceUrl
        }
        return serviceUrl
    },
}

function isNotEmpty(string: any): boolean {
    return _.isString(string) && !!_.trim(string)
}