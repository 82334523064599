import analysisModule from "../analysisModule"
import invoiceModal from "./invoiceViewModal.html"

analysisModule.service("invoiceViewModal", [
    "$q",
    "$http",
    "$modal",
    function ($q, $http, $modal) {
        return function (invoices) {
            return $modal.open({
                template: invoiceModal,
                size: "xl",
                controller: ["$q", "$scope", "oldSpreadsheetService", "$filter",
                    function ($q, $scope, oldSpreadsheetService, $filter) {
                        $scope.invoices = invoices
                        $scope.entityType = "invoice"

                        $scope.exportSpreadsheet = function () {
                            oldSpreadsheetService.exportData("invoice.view.export.spreadsheet", $scope.getInvoicesToExport(), spreadsheetStyle)
                        }

                        $scope.getInvoicesToExport = function () {
                            var invoicesToExport = []
                            $scope.invoices.forEach(function (invoice) {
                                delete invoice.id
                                delete invoice.version
                                var ordersCodes = []
                                invoice.orders.forEach(function (order) {
                                    ordersCodes.push(order.code)
                                })
                                invoice.lastUpdate = $filter("date")(invoice.lastUpdate, "dd/MM/yyyy H:mm")
                                invoice.orders = ordersCodes
                                invoicesToExport.push(invoice)
                            })
                            return invoicesToExport
                        }

                        var spreadsheetStyle = {
                            headers: true,
                            column: {
                                style: {
                                    Font: {
                                        Bold: "1",
                                        Color: "#FFFFFF"
                                    },
                                    Alignment: {
                                        Horizontal: "Center"
                                    },
                                    Interior: {
                                        Color: "#1CBBB2",
                                        Pattern: "Solid"
                                    },

                                },
                                width: 170
                            }
                        }
                    }]
            })
        }
    }
])