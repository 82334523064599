import "angular"

import "front-end-web-commons"
import filterModule from "front-end-web-commons/app/filter/filterModule"
import authenticationModule from "../authentication/authenticationModule"
import addressModule from "front-end-web-commons/app/address/addressModule"
import mapsModule from "front-end-web-commons/app/maps/mapsModule"
import regionModule from "front-end-web-commons/app/region/regionModule"
import routingConfigModule from "front-end-web-commons/app/routingConfig/routingConfigModule"
import analysisModule from "../analysis/analysisModule"
import monitoringCommonsModule from "front-end-web-commons/app/monitoringCommons/monitoringCommonsModule"
import nodeCommandsModule from "front-end-web-commons/app/nodeCommands/nodeCommandsModule"
import commonsCancellationModule from "front-end-web-commons/app/cancellation/commonsCancellationModule"
import tollModule from "front-end-web-commons/app/toll/tollModule"
import commonsOperationModule from "front-end-web-commons/app/operation/commonsOperationModule"
import commonsUserModule from "front-end-web-commons/app/user/commonsUserModule"
import moveToAnotherUserModule from "front-end-web-commons/app/moveToAnotherUser/moveToAnotherUserModule"
import moveTripsToUserModule from "front-end-web-commons/app/moveTripsToUser/moveTripsToUserModule"
import pathModule from "front-end-web-commons/app/path/pathModule"
import dashboardModule from "front-end-web-commons/app/dashboards/dashboardModule"
import nlgTagInputModule from "front-end-web-commons/app/nlgTagInput/nlgTagInputModule"
import nlgPermissionModule from "front-end-web-commons/app/nlgPermission/nlgPermissionModule"
import nlgSelectionModule from "front-end-web-commons/app/nlgSelection/nlgSelectionModule"
import classifiableModule from "front-end-web-commons/app/classifiable/classifiableModule"
import commonsOptimizationModule from "front-end-web-commons/app/optimization/commonsOptimizationModule"
import server from "../server"
import dashboardServer from "../dashboardServer"
import monitoringServer from "../monitoringServer"
import trackingServer from "../trackingServer"
import serviceNameByEntityClass from "./serviceNameByEntityClass.json"
import userManagementServer from "../userManagementServer"
import trackingMobileServer from "../trackingMobileServer"
import workHoursControlServer from "monitoring/app/workHoursControl/config/workHoursControl.json"
import violationModule from "front-end-web-commons/app/violation/violationModule"
import translateModule from "front-end-web-commons/app/translate/translateModule"
import stateByEntityType from "./stateByEntityType.json"
import trackingModule from "nlg-tracking/app/tracking/trackingModule"
import monitorModule from "front-end-web-commons/app/monitor/monitorModule"
import routing from "../routing.json"
import nlgTruckAllocationModule from "front-end-web-commons/app/nlgTruckAllocation/nlgTruckAllocationModule"
import nlgLGPDModule from "front-end-web-commons/app/nlgLGPD/nlgLGPDModule"
import tdnHelpModule from "front-end-web-commons/app/tdnHelp/tdnHelpModule"
import clientViewConfigurationServer from "monitoring/app/clientViewConfiguration/config/clientViewConfiguration.json"
import restrictionConfigurationServer from "monitoring/app/restrictionConfiguration/config/restrictionConfiguration.json"

filterModule.config(["filterDomainProvider", (filterDomainProvider) => {
    filterDomainProvider.registerDomain("r2", server.getBaseUrl())
    filterDomainProvider.setDefaultDomain("r2")
    filterDomainProvider.registerDomain("monitoring", monitoringServer.getMonitoringUrl())
    filterDomainProvider.registerDomain("usermanagement", userManagementServer.getBaseUrl())
    filterDomainProvider.registerDomain("tracking", trackingServer.getBaseUrl())
    filterDomainProvider.registerDomain("tracking-mobile", trackingMobileServer.getBaseUrl())
    filterDomainProvider.registerDomain("work-hours-control", workHoursControlServer.baseUrl)
    filterDomainProvider.registerDomain("client-view-configuration", clientViewConfigurationServer.baseUrl)
    filterDomainProvider.registerDomain("lgpd", "/cockpit-gateway/r2-server/r2")
    filterDomainProvider.registerDomain("restriction-configuration", restrictionConfigurationServer.baseUrl)
}])
    .run(["filterConfiguration", "autocompleteFactory", function (filterConfiguration, autocompleteFactory) {
        filterConfiguration.setFilterGenericAttribute(function (viewValue, additionalInfo) {
            return autocompleteFactory.lazyLoader("generic-attribute", [], additionalInfo)(viewValue)
        })
    }])

classifiableModule.run(["classifiableService", "autocompleteFactory", function (classifiableService, autocompleteFactory) {
    classifiableService.setClassificationsProviderCallback(function (classificationType, viewValue) {
        return autocompleteFactory.lazyLoader("classification", [classificationType], {})(viewValue)
    })
}])

authenticationModule.run(["authenticationService", "filterState", (authenticationService, filterState) => {
    authenticationService.addAuthenticationCallback({
        onLogOut() {
            filterState.reset()
        }
    })
}])

addressModule.config(["addressServiceUrlsProvider", (addressServiceUrlsProvider) => {
    addressServiceUrlsProvider.setUrl({
        countryUrl: "country",
        stateUrl: "state",
        cityUrl: "city",
        geocodingUrl: server.getServiceUrl("geocoding")
    })
}])

regionModule.config(["regionServiceUrlsProvider", (regionServiceUrlsProvider) => {
    regionServiceUrlsProvider.setUrl({
        regionGroupsUrl: "regionGroup",
        regionsByRegionGroupUrlSupplier: function (regionGroupId) {
            return server.getServiceUrl("regionGroup") + regionGroupId + "/regions"
        }
    })
}])

let mapsConfigured = false
let mapsConfigProvider = null

mapsModule.config(["mapsConfigGetterProvider", function (mapsConfigGetterProvider) {
    mapsConfigProvider = mapsConfigGetterProvider
}])
    .config(["mapsPathServiceUrlProvider", function (mapsPathServiceUrlProvider) {
        mapsPathServiceUrlProvider.configure({
            routePathUrl: routing.baseUrl + routing.services.routePath
        })
    }])
    .run(["authenticationService", "$http", (authenticationService, $http) => {
        const onLogIn = () => {
            if (mapsConfigured) {
                return
            }
            mapsConfigured = true
            $http.get(server.getServiceUrl("googleMaps"))
                .then(function (response) {
                    const mapsConfig = response.data
                    mapsConfig.maxStops = parseInt(mapsConfig.maxStops, 10)
                    mapsConfigProvider.registerMapsConfig(mapsConfig)

                })
        }
        authenticationService.addAuthenticationCallback({onLogIn})
        authenticationService.validateToken()
    }])
    .run(["routeStopPopupService", "routeStopPopupCreationService", function (routeStopPopupService, routeStopPopupCreationService) {
        routeStopPopupService.setDelegate(routeStopPopupCreationService)
    }])
    .config(["carrierZoneGrouperServiceProvider", function (carrierZoneGrouperServiceProvider) {
        let serviceUrl = server.getServiceUrl("polygon-carrier-zone-groupers")
        carrierZoneGrouperServiceProvider.setPolygonCarrierZoneGrouperUrl(serviceUrl)
    }])
    .config(["alterStopsServiceProvider", function (alterStopsServiceProvider) {
        alterStopsServiceProvider.configure({
            moveStopUrl: server.getServiceUrl("move-stops"),
            manualOperationSaveUrl: server.getServiceUrl("manualOperationSave"),
            doAlterStopsUrl: server.getServiceUrl("alter-stops"),
            doAlterStopsForTripsUrl: server.getServiceUrl("alter-stop-trips")
        })
    }])

analysisModule.config(["routeServiceUrlProvider", function (routeServiceUrlProvider) {
    routeServiceUrlProvider.configure({
        routeUrlForNodeIdentifier: server.getServiceUrl("routeUrlForNodeIdentifier"),
        routeUrlForTripIds: server.getServiceUrl("routeUrlForTripIds"),
        alterStopsUrl: server.getServiceUrl("trip.stops")
    })
}])

tollModule.config(["tollServiceUrlProvider", function (tollServiceUrlProvider) {
    tollServiceUrlProvider.configure({
        calculateTollUrlForNodeIdentifier: server.getServiceUrl("calculateTollUrlForNodeIdentifier")
    })
}])

routingConfigModule.config(["routingSelectionDialogProvider", function (routingSelectionDialogProvider) {
    routingSelectionDialogProvider.setRoutingConfigSelectorServiceUrl(server.getServiceUrl("routingConfigSelector"))
}])

monitoringCommonsModule.config(["monitoringCommonsServiceUrlsProvider", function (monitoringCommonsServiceUrlsProvider) {
    monitoringCommonsServiceUrlsProvider.setUrl({
        filterTemplateUrl: monitoringServer.getServiceUrl("trip"),
        filterUrl: monitoringServer.getServiceUrl("tripFilter"),
    })
}])

nodeCommandsModule.run(["rootNodeCommandExecutorSupplier", "r2NodeCommands", function (rootNodeCommandExecutorSupplier, r2NodeCommands) {
    rootNodeCommandExecutorSupplier.setRoot(r2NodeCommands)
}])

commonsCancellationModule.run(["cancellationServiceInvoker", "r2CancellationService", function (cancellationServiceInvoker, r2CancellationService) {
    cancellationServiceInvoker.setService(r2CancellationService)
}])

commonsOperationModule.run(["organizeService", "organizeModal", function (organizeService, organizeModal) {
    organizeService.setModalService(organizeModal)
}])
    .run(["preViolationModal", "$state", function (preViolationModal, $state) {
        preViolationModal.setLocalityEditionCallback(function (localityId, version) {
            return $state.href("r2.edition", {entityType: "locality", entityId: localityId, version: version})
        })
    }])

pathModule.config(["pathServiceProvider", function (pathServiceProvider) {
    pathServiceProvider.setPaths({
        "preValidation-optimization": server.getServiceUrl("preValidation-optimization"),
        "preValidation-manualOperation": server.getServiceUrl("preValidation-manualOperation"),
        "organize": server.getServiceUrl("organize"),
        "organize-execute": server.getServiceUrl("organize-execute"),
        "manualOperation-save": server.getServiceUrl("manualOperationSave"),
        "geocodeAndStamp": server.getServiceUrl("geocodeLocalities"),
        "finalize-trip": server.getServiceUrl("finalize-trip"),
        "move-trip-to-user": server.getServiceUrl("move-trip-to-user"),
        "move-deliveryunit-to-user": server.getServiceUrl("move-deliveryunit-to-user"),
        "users": server.getRelativeUrl("users"),
        "operation-overview": server.getServiceUrl("operation-overview")
    })
}])

commonsOptimizationModule.run(["optimizationServiceInvoker", "r2OptimizationService", function (optimizationServiceInvoker, r2OptimizationService) {
    optimizationServiceInvoker.setService(r2OptimizationService)
}])

dashboardModule.config(["dashboardDomainProvider", function (dashboardDomainProvider) {
    dashboardDomainProvider.setDefaultDomain("r2")
    dashboardDomainProvider.registerDomain("r2", dashboardServer.getDashboardUrl())
    dashboardDomainProvider.setDashboardPaths("r2", {
        infoUrl: dashboardServer.getServiceUrl("info"),
        queryUrl: dashboardServer.getServiceUrl("query"),
        exportChartUrl: dashboardServer.getServiceUrl("export"),
        countExportUrl: dashboardServer.getServiceUrl("countExport")
    })
    dashboardDomainProvider.registerDomain("gcp", server.getBaseUrl() + "/gcp-overview")
    dashboardDomainProvider.setDashboardPaths("gcp", {
        infoUrl: dashboardServer.getServiceUrl("info"),
        queryUrl: dashboardServer.getServiceUrl("query"),
        exportChartUrl: dashboardServer.getServiceUrl("export"),
        countExportUrl: dashboardServer.getServiceUrl("countExport")
    })
}]).run(["spreadsheetServiceInvoker", "oldSpreadsheetService", function (spreadsheetServiceInvoker, oldSpreadsheetService) {
    spreadsheetServiceInvoker.setService(oldSpreadsheetService)
}])

commonsUserModule.config(["commonsUserServiceProvider", function (commonsUserServiceProvider) {
    commonsUserServiceProvider.setCurrentUserUrl(server.getServiceUrl("current-user"))
}])

moveToAnotherUserModule.config(["moveToAnotherUserModalProvider", function (moveToAnotherUserModalProvider) {
    moveToAnotherUserModalProvider.shouldLoadCurrentUser(true)
}])

moveTripsToUserModule.config(["moveTripsToUserModalProvider", function (moveTripsToUserModalProvider) {
    moveTripsToUserModalProvider.shouldLoadCurrentUser(true)
}])

nlgTagInputModule.config(["nlgTagInputLinkServiceProvider", function (nlgTagInputLinkServiceProvider) {
    const hasOwnProperty = Object.hasOwnProperty
    nlgTagInputLinkServiceProvider.setLinkService(function (tag) {
        if (!tag.entityClass) {
            return null
        }
        if (tag.entityClass === "br.com.neolog.model.entity.dto.LGPDObfuscatedEntityDTO") {
            //utilizado para remover o link da entidade ofuscada
            //Issue - NLGPRODUTO-1704/NLGPRODUTO-2196
            return null
        }
        if (!hasOwnProperty.call(serviceNameByEntityClass, tag.entityClass)) {
            return null
        }
        return `r2.edition({ entityType: '${serviceNameByEntityClass[tag.entityClass]}', entityId: ${tag.id} })`
    })
}])

nlgPermissionModule.config(["permissionServiceProvider", function (permissionServiceProvider) {
    permissionServiceProvider.setAuthorizationUrl(server.getServiceUrl("authorization"))
}])

nlgSelectionModule.config(["$stateProvider", function ($stateProvider) {
    $stateProvider.state("r2.selection", {
        data: {
            pageTitle: "{{'title.r2.selection' | translate}}"
        },
        url: "/programming/selection",
        template: "<nlg-selection-view></nlg-selection-view>",
    })
}]).config(["nlgSelectionServiceProvider", function (nlgSelectionServiceProvider) {
    nlgSelectionServiceProvider.setOrderBasketSelectionUrl(server.getServiceUrl("orderBasketSelectionUrl"))
}])

violationModule.config(["dtoLinkServiceProvider", (dtoLinkServiceProvider) => {
    dtoLinkServiceProvider.setRouteSupplier((dto) => {
        return `r2.edition({ entityType: '${stateByEntityType[dto.type]}', entityId: ${dto.id} })`
    })
}])

translateModule.config(["$translateProvider", ($translateProvider) => {
    $translateProvider.usePostCompiling(true)
}])

trackingModule.config(["markerImageProvider", function (markerImageProvider) {
    var image = {
        icon: "images/truck.png",
        violationIcon: "images/truck_violation.png",

        created: "images/truck_created.png",
        inExecution: "images/truck_in_execution.png",
        delayed: "images/truck_delayed.png",
        inService: "images/truck_in_service.png",
        pendingOccurrence: "images/truck_pending_occurrence.png",
        withoutMonitorable: "images/truck_without_monitorable.png",

        signal_late: "images/signal_late.png",
        created_late: "images/truck_created_late.png",
        inExecution_late: "images/truck_in_execution_late.png",
        delayed_late: "images/truck_delayed_late.png",
        inService_late: "images/truck_in_service_late.png",
        pendingOccurrence_late: "images/truck_pending_occurrence_late.png",
        withoutMonitorable_late: "images/truck_without_monitorable_late.png",
    }
    markerImageProvider.setIcon(image)
}])

monitorModule.config(["monitorUrlServiceProvider", function (monitorUrlServiceProvider) {
    monitorUrlServiceProvider.setGatewayUrl("/cockpit-gateway/")
}])

nlgTagInputModule.config(["nlgTagInputIconMapProvider", function (nlgTagInputIconMapProvider) {
    var defaultIconPath = "/images/loading2.gif"
    nlgTagInputIconMapProvider.config(defaultIconPath)
}]).run(["nlgTagInputIconMapMonitoring", "nlgTagInputIconMap", function (nlgTagInputIconMapMonitoring, nlgTagInputIconMap) {
    nlgTagInputIconMap.addIcons(nlgTagInputIconMapMonitoring.iconMap())
}])

nlgTruckAllocationModule.config(["nlgTruckAllocationProvider", function (nlgTruckAllocationProvider) {
    nlgTruckAllocationProvider.setFilterService({
        filter: server.getRelativeUrl("truck-allocation"),
        service: server.getServiceUrl("truck-allocation"),
        find: server.getServiceUrl("truck-allocation") + "/find"
    })
}])

tdnHelpModule.config(["tdnHelpProvider", function (tdnHelpProvider){
    tdnHelpProvider.setContext("TRE")
}])

nlgLGPDModule.config(["nlgLGPDProvider", function (nlgLGPDProvider) {
    nlgLGPDProvider.setServicePath({
        attributePath: "/lgpd/attributable",
        classificationPath: "/lgpd/classifiable",
        genericParamPath: "/lgpd/generic-param",
        filterServicePath: "/lgpd/entity",
        sqlFilterServicePath: "/lgpd/script-sql",
        mainArtifactName: "r2",
        availableServices: [
            {
                artifactName: "r2"
            },
            {
                artifactName: "monitoring"
            },
            {
                artifactName: "tracking"
            },
            {
                artifactName: "userManagement"
            }
        ],
    })
}])