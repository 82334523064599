import localityModule from "./localityModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"

localityModule.controller("localityController",
    ($scope, sourceEntity, entity, localityService, entityEditionService, nlgTagInputIconMap, countryLanguages, $translate) => {
        $scope.defaultCountry = countryLanguages.findByLanguage($translate.use()).iso2
        $scope.model = {
            countryData: null
        }

        const updateService = entityEditionService.crudHelper("locality", sourceEntity)
        $scope.entity = entity
        $scope.monitorableEntity = entity.monitorableLocalityDTO
        $scope.restriction = {
            enabled: !!entity.r2DeliveryRestriction
        }

        localityService.findPossibleIncompatibleVehicles().then((vehicles) => {
            $scope.vehicles = vehicles
        })

        localityService.findPossibleAllowedTrucks().then((trucks) => {
            $scope.trucks = trucks
        })


        localityService.findPreDefinedWorkingHours()
            .then((preDefinedWorkingHours) => {
                $scope.preDefinedWorkingHours = preDefinedWorkingHours
            })

        $scope.logisticRoles = [
            "br.com.neolog.model.LogisticRole.BOTH",
            "br.com.neolog.model.LogisticRole.DESTINATION",
            "br.com.neolog.model.LogisticRole.ORIGIN"
        ]

        let oldLogisticRole = $scope.entity.logisticRole

        $scope.getLogisticRoles = () => {
            return arrays.filter($scope.logisticRoles, (logisticRole) => {
                if (!$scope.entity.id) {
                    return true
                }
                return [oldLogisticRole, "br.com.neolog.model.LogisticRole.BOTH"].indexOf(logisticRole) !== -1
            })
        }

        $scope.isOrigin = () => {
            return $scope.entity.logisticRole === "br.com.neolog.model.LogisticRole.ORIGIN"
        }

        $scope.onRestrictionChanged = () => {
            if ($scope.restriction.enabled) {
                $scope.entity.r2DeliveryRestriction = null
            }
        }

        $scope.save = () => {
            if (entity.allowedTrucks.length === $scope.trucks.length) {
                entity.allowedTrucks = []
            }
            $scope.monitorableEntity.updateMonitorableAddress = isFilledMonitorableLocality() || isFilledContactInfo()
            updateService.save(entity)
        }

        function isFilledMonitorableLocality() {
            let {localityType, localityIcon} = $scope.monitorableEntity
            return isFilled(localityType) || isFilled(localityIcon)
        }

        function isFilledContactInfo() {
            if (entity.mainContact !== null) {
                return isFilled(entity.mainContact.name) || isFilled(entity.mainContact.email) || isFilled(entity.mainContact.phone)
            }
            return false
        }

        function isFilled(value) {
            return !!value && !!value.length
        }

        $scope.onLatLongChanged = function () {
            $scope.entity.address.geocodificationType = "br.com.neolog.model.GeocodificationType.MANUAL"
        }

        $scope.getLocalityTypes = function () {
            return localityService.getLocalityTypes()
        }

        $scope.getLocalityIcons = function () {
            return localityService.getLocalityIcons()
        }
        $scope.getIconPreview = function () {
            if ($scope.monitorableEntity) {
                return nlgTagInputIconMap.iconMapBig()[$scope.monitorableEntity.localityIcon]
            }
        }
    }
)