import panelModule from "./r2PanelModule"
import panelFooterView from "./panelFooterView.html"

panelModule.directive("nlgPanelFooter", [()=>{
    return {
        restrict: "E",
        template: panelFooterView,
        transclude: true,
        scope: {
            entityName: "@?",
            saveAction: "&",
            hideBackButton: "=?",
            hideSaveButton: "=?"
        },
        controller: ["$scope", "$state", function ($scope, $state) {
            $scope.back = ()=>{
                return $state.go("r2.search", {
                    entity: $scope.entityName,
                    reload: true
                })
            }
        }]
    }
}])