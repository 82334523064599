import {configParamsModule} from "../configParamsModule";
import {ConfigParamsService} from "../configParamsService";
import {RemoteServicesConfigParams} from "../model/remoteServicesConfigParams";
import {IModalService, IModalServiceInstance} from "angular-ui-bootstrap";
import {LoadingService, MessagesModal, RemoteExceptionHandler} from "../../../bower_components/front-end-web-commons"

const arrays = require("front-end-web-commons/app/arrays/arrays")

class RemoteServersModalController {
    remoteServicesConfigParams: RemoteServicesConfigParams[];
    defaultServer: number;
    oldDefaultServer: number;
    isOrchestratorEnabled: boolean = false;

    constructor(private $modalInstance: IModalServiceInstance,
                private loadingService: LoadingService,
                private messagesModal: MessagesModal,
                private remoteConfigParam: RemoteServicesConfigParams[],
                private configParamsService: ConfigParamsService,
                private remoteExceptionHandler: RemoteExceptionHandler) {
        this.remoteServicesConfigParams = remoteConfigParam
        this.defaultServer = 0
        this.oldDefaultServer = 0
        this.loadingService(this.configParamsService.isOrchestratorEnabled())
            .catch(this.remoteExceptionHandler())
            .then((isEnabled: boolean) => {
                this.isOrchestratorEnabled = isEnabled ;
            });
    }

    testConnection(remoteConfig: RemoteServicesConfigParams[]) {
        return this.loadingService(this.configParamsService.testRoutingConnection(remoteConfig))
            .catch(this.remoteExceptionHandler())
            .then((messages) => this.messagesModal("dialog.result", messages))
    }

    addRemoteServers() {
        this.remoteServicesConfigParams.push({
            defaultServer: false,
            name: "",
            sourceId: "",
            remoteServicesBaseUrl: "",
            isOrchestrated: false,
            hasExternalIp: false,
            type: ""
        });
    };

    removeRemoteServers(index: number) {
        if (this.isRemoveDisabled(this.remoteServicesConfigParams[index])) {
            return
        }
        arrays.removeAt(this.remoteServicesConfigParams, index);
    };

    isRemoveDisabled(remoteConfig: RemoteServicesConfigParams) {
        return !!remoteConfig.defaultServer;
    };

    updateDefault() {
        const remoteServicesConfigParams = this.remoteServicesConfigParams;
        remoteServicesConfigParams[this.defaultServer].defaultServer = true
        remoteServicesConfigParams[this.oldDefaultServer].defaultServer = false
        this.oldDefaultServer = this.defaultServer
    }

    ok(remoteServersConfig: RemoteServicesConfigParams[]) {
        this.loadingService(this.configParamsService.updateRemoteServicesConfigParams(remoteServersConfig))
            .catch(this.remoteExceptionHandler())
            .then(() => this.$modalInstance.close(remoteServersConfig));
    };

    cancel() {
        this.$modalInstance.dismiss();
    };
}

configParamsModule.controller("remoteServersModalController", RemoteServersModalController);

export class RemoteServersModalService {
    constructor(
        private $modal: IModalService) {
    }

    open() {
        return this.$modal.open({
            size: "xl",
            templateUrl: require("link!./remoteServersView.html"),
            resolve: {
                remoteConfigParam(configParamsService: ConfigParamsService,
                                  loadingService: LoadingService,
                                  remoteExceptionHandler: RemoteExceptionHandler) {
                    "ngInject"
                    return loadingService(configParamsService.getRemoteServicesConfigParams()).catch(remoteExceptionHandler())
                }
            },
            controllerAs: "ctrl",
            controller: "remoteServersModalController",
        }).result;

    }
}

configParamsModule.service("remoteServersModalService", RemoteServersModalService)