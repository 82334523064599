import angular from "angular"
import editionModule from "./editionModule"
import server from "../server"
import jiff from "jiff"

editionModule.service("entityEditionService", ["$http", "$state", "remoteExceptionHandler", "messagesModal", "loadingService", function ($http, $state, remoteExceptionHandler, messagesModal, loadingService) {

    class EntityHelper{
        constructor(entityType, entity){
            this.entityType = entityType
            this.entityCopy = angular.copy(entity)
        }

        save(modifiedEntity){
            var bundle = "entity.validator.successMessage"
            if(modifiedEntity.id){
                bundle = "entity.validator.update.successMessage"
            }
            return loadingService($http.post(this.getServiceUrl(), this.generatePatches(modifiedEntity))
                .catch(remoteExceptionHandler()))
                .then((response) => {
                    return messagesModal("dialog.success", [{
                        keyBundle: bundle,
                        parameters: []
                    }]).finally(() => {
                        if($state.$current.name === "r2.edition"){
                            $state.reload()
                        } else {
                            const entityId = response.data
                            $state.go("r2.edition", {entityType: this.entityType, entityId })
                        }
                    })
                })
            }

        generatePatches(modifiedEntity){
            return jiff.diff(this.entityCopy, angular.copy(modifiedEntity), {invertible: false})
        }

        getServiceUrl(){
            throw new Error()
        }
    }

    class UpdateHelper extends EntityHelper{
        getServiceUrl(){
            return getEditionServiceUrl(this.entityType) + this.entityCopy.id + "/version/" + this.entityCopy.version
        }
    }

    class CreateHelper extends EntityHelper{
        getServiceUrl(){
            return getEditionServiceUrl(this.entityType) + "new"
        }
    }

    this.crudHelper = (entityType, entity) => {
        if(entity.id && entity.version >= 0){
            return new UpdateHelper(entityType, entity)
        }
        return new CreateHelper(entityType, entity)
    }

    function getEditionServiceUrl(entityType){
        return server.getServiceUrl("edition." + entityType)
    }
}])