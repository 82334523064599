import analysisMapModule from "./analysisMapModule"
import arrays from "../../bower_components/front-end-web-commons/app/arrays/arrays"
import _ from "lodash"

analysisMapModule.controller("analysisMapController", [
    "$scope",
    "$modal",
    "$http",
    "loadingService",
    "organizeService",
    "messagesModal",
    "remoteExceptionHandler",
    "$q",
    "analysisMapService",
    "analysisMapPreViolationService",
    "markerService",
    "markerImage",
    "routeService",
    "detailsModal",
    "manualOperationOptionsModal",
    "manualOperationToTripModal",
    "nodeIdentifiers",
    ($scope, $modal, $http, loadingService, organizeService, messagesModal, remoteExceptionHandler, $q, analysisMapService, analysisMapPreViolationService, markerService, markerImage, routeService, orderDetailsModal, manualOperationOptionsModal, manualOperationToTripModal, nodeIdentifiers) => {
    let baskets = {
        orders: null,
        trips: null
    }
    $scope.baskets = baskets
    $scope.selection = {
        deliveryUnits: {},
        tripBasket: {},
    }
    $scope.registerBasketApi = (basketApiName, api) => baskets[basketApiName] = api

    $scope.refilterBaskets = () => {
        baskets.trips.refreshFilter()
        baskets.orders.refreshFilter()
    }
    $scope.resume = function(){
        baskets.trips.refreshFilter()
    }
    $scope.$on("OnRouteColorChanged", function(event, changeColorData){
        baskets.trips.changePresentationIconColor(changeColorData)
    })
    $scope.$on("OnRouteClick", function(event, trip){
        baskets.trips.toggleSelection(trip.id, "Trip")
    })

    $scope.routes = []
    $scope.orderNodes = []
    $scope.orderMarkers = []
    $scope.selectedOrderMarkers = []
    $scope.isOrderCollapsed =  false
    $scope.isTripCollapsed =  true

    let routesApi
    $scope.registerRoutesApi = function(api) {
        routesApi = api
        setDirectiveProperties()
    }

    $scope.getRoutes = function(rootNode){
        routeService.getRouteFromNodeIdentifiers(rootNode.children)
            .then(function (routes) {
                if (!_.isEmpty(routes)) {
                    $scope.isTripCollapsed = true
                }
                routesApi.setRoutes(routes)
            })
    }

    $scope.$on("OnNodeSelected", function (event, minimalNode) {
        if (minimalNode.type.name.toLowerCase() === "order") {
            let selectedOrderNodes = _.map($scope.selection.deliveryUnits.Order, function (orderNode) {
                return orderNode.presentationId
            })
            let selectedOrderMarkers = _.map($scope.selectedOrderMarkers, function (orderMarker) {
                return orderMarker.identifier
            })
            let sourceIdsToSelect = _.difference(selectedOrderNodes,selectedOrderMarkers)
            let sourceIdsToRemove = _.difference(selectedOrderMarkers,selectedOrderNodes)
            if (!_.isEmpty(sourceIdsToSelect)){
                let markersToSelect = _.filter($scope.orderMarkers, function (marker) {
                    return arrays.contains(sourceIdsToSelect, marker.identifier)
                })
                routesApi.toggleMapMarkers(markersToSelect)
            }
            if (!_.isEmpty(sourceIdsToRemove)){
                let markersToRemove = _.filter($scope.orderMarkers, function (marker) {
                    return arrays.contains(sourceIdsToRemove, marker.identifier)
                })
                routesApi.toggleMapMarkers(markersToRemove)
            }
        }
    })

    $scope.$watchCollection( "selectedOrderMarkers", function () {
        let selectedOrderNodes = _.map($scope.selection.deliveryUnits.Order, function (orderNode) {
            return orderNode.presentationId
        })
        let selectedOrderMarkers = _.map($scope.selectedOrderMarkers, function (orderMarker) {
            return orderMarker.identifier
        })
        let sourceIdsToSelect = _.difference(selectedOrderMarkers,selectedOrderNodes)
        let sourceIdsToDeselect = _.difference(selectedOrderNodes,selectedOrderMarkers)
        if (!_.isEmpty(sourceIdsToSelect)){
            sourceIdsToSelect.forEach(function (sourceId) {
                let node = _.filter($scope.orderNodes,function (orderNode) {
                    return orderNode.presentationId === sourceId
                })[0]
                $scope.baskets.orders.toggleSelection( node.id ,"Order")
            })
        }
        if (!_.isEmpty(sourceIdsToDeselect)){
            sourceIdsToDeselect.forEach(function (sourceId) {
                let node = _.filter($scope.orderNodes,function (orderNode) {
                    return orderNode.presentationId === sourceId
                })[0]
                $scope.baskets.orders.toggleSelection( node.id ,"Order")
            })
        }
    })

    $scope.getMarkers = function(ordersRootNode){
        $scope.orderNodes = ordersRootNode.children
        let orderSourceIds = _.map($scope.orderNodes, function (node) {
            return node.presentationId
        })
        analysisMapService.findMarkers(orderSourceIds)
            .then(function (markersToValidate) {
                let validMarkers = validateAndRemoveMarkersWithoutGeographicInfo(markersToValidate)
                $scope.orderMarkers = buildMarkers(validMarkers)
                if (!_.isEmpty($scope.orderMarkers)) {
                    $scope.isOrderCollapsed = true
                }
                routesApi.cleanMapMarkersSelection()
            })
    }

    $scope.$on("OnMapMarkerAction", function (event, dataToDoManualOperation) {
        if (dataToDoManualOperation) {
            let filteredRoutes = _.filter($scope.routes, function (route) {
                return arrays.contains(dataToDoManualOperation.tripCodes, route.tripCode)
            })
            let orderNodes = _.filter($scope.orderNodes,function (orderNode) {
                return arrays.contains(dataToDoManualOperation.orderCodes, orderNode.presentationId)
            })
            let orderItemNodes = _.flatMap(orderNodes,function (orderNode) {
                return _.map(orderNode.children, function (orderItemNode) {
                    return orderItemNode
                })
            })
            let manualOperationTemplate = getManualOperationTemplate(orderItemNodes)
            if (filteredRoutes.length === 1){
                return $scope.doManualOperation(manualOperationTemplate, filteredRoutes[0].presentationNode)
            }
            return $scope.getSelectedTripToOperation(filteredRoutes).then( (trip) => {
                return $scope.doManualOperation(manualOperationTemplate, trip)
            })
        }
    })

    function validateAndRemoveMarkersWithoutGeographicInfo(markers) {
        const removedMarkers = _.remove(markers.result, function (marker) {
            return !marker.geographicInfo
        })
        if (removedMarkers.length > 0) {
            messagesModal("dialog.warning", [{
                keyBundle: "analysis.map.markers.without.geographicInfo"
            }])
        }
        return markers
    }

    function buildMarkers(orderMarkers){
        const markers = []
        orderMarkers.forEach( function(orderMarker){
            let marker = markerService.createMarker(
                orderMarker.sourceId,
                orderMarker.markerKey,
                orderMarker.geographicInfo,
                "images/marker-pin.png"
            )
            marker.options.draggable = true
            marker.orderSummary = orderMarker.orderSummary
            markers.push(marker)
        })
        return markers
    }

    $scope.showMoreDetails = function () {
        loadingService(analysisMapService.getOrdersDetails(getSelectedOrdersSourceIds()))
            .then((response) => {
                orderDetailsModal(response)
            }).catch(remoteExceptionHandler())
    }

    $scope.cleanSelection = function () {
        messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "analysis.map.dialog.clean.selection.confirmation"
        }]).then( () => {
            routesApi.cleanMapMarkersSelection()
        })
    }

    $scope.selectAllMarkers = function () {
        if (_.isEqual(_.sortBy($scope.selectedOrderMarkers), _.sortBy($scope.orderMarkers))){
            messagesModal("dialog.success", [{
                keyBundle: "analysis.map.dialog.select.all.already.done"
            }])
            return
        }
        messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "analysis.map.dialog.select.all.confirmation"
        }]).then( () => {
            routesApi.selectAllMapMarkers()
        })
    }

    $scope.$watchCollection( "orderMarkers", function () {
        if (!_.isEmpty($scope.orderMarkers)){
            routesApi.centerOnTrucks()
        }
    })

    $scope.centerOnSelectedMarkers = function () {
        if (!_.isEmpty($scope.selectedOrderMarkers)) {
            routesApi.centerOnSelectedMapMarkers()
        }
    }

    $scope.getTotalQuantityOfOrders = function () {
        return $scope.selectedOrderMarkers.length
    }

    $scope.getTotalQuantityOfOrderItems = function () {
        return _.sum( $scope.selectedOrderMarkers.map(marker => marker.orderSummary.totalQuantityOfOrderItems ) )
    }

    $scope.getTotalVolume = function () {
        return _.sum( $scope.selectedOrderMarkers.map(marker => marker.orderSummary.totalVolume ) )
    }

    $scope.getTotalWeight = function () {
        return _.sum( $scope.selectedOrderMarkers.map(marker => marker.orderSummary.totalWeight ) )
    }

    $scope.getTotalValue = function () {
        return _.sum( $scope.selectedOrderMarkers.map(marker => marker.orderSummary.totalValue ) )
    }

    $scope.openSuccessMessageModal = function (message) {
        return messagesModal("dialog.success", [message])
    }

    $scope.doOptimization = function () {
        messagesModal.cancellable("dialog.proceed?", [{
            keyBundle: "analysis.map.dialog.optimize.confirmation"
        }]).then( () => {
            analysisMapPreViolationService.getViolationsForOptimization( getNodeIds($scope.selection.deliveryUnits.DeliveryUnit) )
                .then(() =>  {
                    return loadingService(
                        analysisMapService.doOptimize( getNodeIds($scope.selection.deliveryUnits.DeliveryUnit) )
                    )
                }).catch(remoteExceptionHandler())
                .then(() => {
                    routesApi.cleanMapMarkersSelection()
                    return $scope.openSuccessMessageModal({
                        keyBundle: "programming.optimization.successful.submit"
                    }).then(() => {
                        $scope.refilterBaskets()
                    })
                })
        })
    }

    $scope.manualOperations = function () {
        $scope.getChosenOperation()
            .then( (chosenOperation) => {
                switch (chosenOperation) {
                    case "TRIP":
                        if (!$scope.routes || _.isEmpty($scope.routes)){
                            messagesModal("dialog.warning", [{
                                keyBundle: "analysis.map.manual.operation.toTrip.no.filtered.trips"
                            }])
                            return
                        }
                        return $scope.getSelectedTripToOperation($scope.routes).then( (trip) => {
                            return $scope.doManualOperation($scope.selection, trip)
                        })
                    default:
                        return $scope.doManualOperation($scope.selection, $scope.baskets.trips.getData())
                }
            })
    }

    $scope.removeOrdersFromTrip = function () {
        return $scope.doManualOperation($scope.selection, $scope.baskets.orders.getData())
    }

    $scope.getChosenOperation = function () {
        return manualOperationOptionsModal().result.then(function (result) {
            return result.chosenOperation
        })
    }

    $scope.getSelectedTripToOperation = function (routes) {
        return manualOperationToTripModal(routes).result.then(function (result) {
            return result.selectedTripNode
        })
    }

    $scope.doManualOperation = function (manualOperationSelection, target) {
        organizeService.organize(
            manualOperationSelection,
            target
        ).then(() => {
            $scope.refilterBaskets()
        })
    }

    $scope.getSelectedOrdersFromTripBasket = ()=> {
        return nodeIdentifiers.toNodeIdentifiers($scope.selection.tripBasket.DeliveryUnit || [])
    }

    $scope.getSelectedTrips = ()=> {
        return nodeIdentifiers.toNodeIdentifiers($scope.selection.tripBasket.Trip || [])
    }

    function getNodeIds(nodes) {
        return nodeIdentifiers.toNodeIdentifiers(nodes || []).map(function (node) {
            return node.id
        })
    }

    function getSelectedOrdersSourceIds() {
        return _.map($scope.selectedOrderMarkers, function (marker) {
            return marker.identifier
        })
    }

    function getManualOperationTemplate(orderItemNodes) {
        return {
            deliveryUnits: {
                DeliveryUnit: formatNodeIdsToDoManualOperation(getNodeIds(orderItemNodes)),
                Order: []
            },
            tripBasket: {}
        }
    }

    function formatNodeIdsToDoManualOperation(idsToDoManualOperation) {
        let formattedData = []
        _.forEach(idsToDoManualOperation, function (id) {
            formattedData.push({
                id: id,
                type: {
                    messageKey: "presentationnode.nodetype.delivery.unit",
                    name: "DeliveryUnit"
                },
                $parent: {
                    type: {
                        messageKey: "presentationnode.nodetype.routingBasket",
                        name: "ROUTING_BASKET"
                    }
                }
            })
        })
        return formattedData
    }

    function setDirectiveProperties() {
        if (routesApi.setDrawingManagerReadOnly){
            routesApi.setDrawingManagerReadOnly(false)
            routesApi.setFreeHandLifeCycle(false)
            routesApi.setOverlayTypeEditable(false)
            routesApi.setRectangleOverlayType(true)
            routesApi.setRectangleAutoDelete(true)
            routesApi.setZoomOnClickMarkersCluster(true)
        }
    }
}])
