import analysisModule from "../analysisModule"

analysisModule.service("r2NodeCommands", [
    "NodeCommandExecutor",
    "commonNodeCommandExecutor",
    "nodeCommandResolveOrderBreak",
    "nodeCommandServeTrip",
    "nodeCommandViewTripEdition",
    "nodeCommandSelectNodes",
    "nodeCommandPublishTrip",
    "nodeCommandUnselectNodes",
    "nodeCommandReprogramTrip",
    "nodeCommandInvoiceView",
    "nodeCommandStartMonitorableConfiguration",
    function (NodeCommandExecutor,
              commonNodeCommandExecutor,
              nodeCommandResolveOrderBreak,
              nodeCommandServeTrip,
              nodeCommandViewTripEdition,
              nodeCommandSelectNodes,
              nodeCommandPublishTrip,
              nodeCommandUnselectNodes,
              nodeCommandReprogramTrip,
              nodeCommandInvoiceView,
              nodeCommandStartMonitorableConfiguration) {
        let commandMap = {
            "nodeCommandResolveOrderBreak": nodeCommandResolveOrderBreak,
            "nodeCommandServeTrip": nodeCommandServeTrip,
            "nodeCommandViewTripEdition": nodeCommandViewTripEdition,
            "selectNodesCommand": nodeCommandSelectNodes,
            "unselectNodesCommand": nodeCommandUnselectNodes,
            "nodeCommandPublishTrip": nodeCommandPublishTrip,
            "UnreleaseTripCommand": nodeCommandReprogramTrip,
            "nodeCommandInvoiceView": nodeCommandInvoiceView,
            "nodeCommandStartMonitorableConfiguration":nodeCommandStartMonitorableConfiguration,
        }
        return new NodeCommandExecutor(commandMap, commonNodeCommandExecutor)
    }])